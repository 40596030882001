import { AxiosResponse, AxiosError } from "axios";

import { MISData, MISUploadCall } from "./typings";
import { MISUploadRequestActionType, PaymentChannel } from "./constants";
import { ApiUrl } from "../api/constants";
import { backendAxiosInstance } from "../api/axios";
import { AppThunk, BEApiError } from "../api/typings";
import { generateErrorFromResponse } from "../api/utils";
import { authLogout } from "../auth/actions";

export const uploadMIS = (
  paymentChannel: PaymentChannel,
  mis: MISData[]
): AppThunk<
  Promise<AxiosResponse<MISUploadCall["response"]["success"]>>
> => async (
  dispatch
): Promise<AxiosResponse<MISUploadCall["response"]["success"]>> => {
  dispatch({
    type: MISUploadRequestActionType.loading,
  });
  return backendAxiosInstance
    .post<MISUploadCall["response"]["success"]>(ApiUrl.MIS_UPLOAD, {
      paymentChannel,
      mis,
    } as MISUploadCall["payload"])
    .then((response) => {
      dispatch({
        type: MISUploadRequestActionType.successful,
        payload: response.data,
      });
      return response;
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 403) {
        dispatch(authLogout());
      } else {
        dispatch({
          type: MISUploadRequestActionType.failed,
          payload: generateErrorFromResponse<BEApiError>(error),
        });
      }
      throw error;
    });
};
