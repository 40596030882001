import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Papa from "papaparse";
import fileDownload from "js-file-download";
import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import get from "lodash/get";

import { RootState } from "../../data/reducers";
import {
  hasLoadableLoaded,
  isLoadableInProgress,
} from "../../data/loadable/loadable";
import { Routes } from "../../App";
import { initializeSettlement } from "../../data/settlement/actions";
import { UpdatedTransaction } from "./typings";
import { uploadMIS } from "../../data/MIS/actions";
import { updateTransactions } from "../../data/transaction/actions";

export const ClearingTransaction = () => {
  const [updatedTransactions, setUpdatedTransactions] = useState<
    UpdatedTransaction[]
  >([]);
  const [isRefreshBtnActive, setIsRefreshBtnActive] = useState<boolean>(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isRefreshBtnActive) {
      timeout = setTimeout(() => {
        setIsRefreshBtnActive(true);
      }, 30 * 1000);
    }
    return () => timeout && clearTimeout(timeout);
  }, [isRefreshBtnActive]);

  const misInputLoadable = useSelector(
    (state: RootState) => state.misReducer.misInputLoadable
  );
  const misUploadLoadable = useSelector(
    (state: RootState) => state.misReducer.misUploadLoadable
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [settlementInitiated, setSettlementInitiated] = useState<boolean>(
    false
  );

  if (!hasLoadableLoaded(misInputLoadable)) {
    history.push(Routes.CLEARING_MIS_UPLOAD);
    return null;
  }

  const updatedPlatformBillIds = updatedTransactions.map(
    (a) => a.platformBillID
  );

  const reconRequiredHeader = "Recon Required";
  const reconRequiredKey = "reconRequired";

  const columns: Handsontable.ColumnSettings[] = [
    {
      data: "platformBillID",
      name: "Platform Bill ID",
      type: "text",
      readOnly: true,
    },
    {
      data: "billerBillID",
      name: "Biller Bill ID",
      type: "text",
      readOnly: true,
    },
    {
      data: reconRequiredKey,
      name: reconRequiredHeader,
      type: "checkbox",
      renderer: function (instance, td, row) {
        if (
          hasLoadableLoaded(misUploadLoadable) &&
          updatedPlatformBillIds.includes(
            misUploadLoadable.data.data[row].platformBillID
          )
        ) {
          td.style.background = "red";
        }
        // @ts-ignore
        Handsontable.renderers.CheckboxRenderer.apply(this, arguments);
        return td;
      },
    },
    {
      data: "discrepancyAmount",
      name: "Discrepancy Amount",
      type: "numeric",
      readOnly: true,
    },
    { data: "txnRefID", name: "Txn Ref ID", type: "numeric", readOnly: true },
    { data: "payerID", name: "Payment App ID", type: "text", readOnly: true },
    { data: "payerName", name: "Payment App", type: "text", readOnly: true },
    { data: "billerID", name: "Biller ID", type: "text", readOnly: true },
    { data: "billerName", name: "Biller", type: "text", readOnly: true },
    { data: "status", name: "Status", type: "text", readOnly: true },
    { data: "network", name: "Network", type: "text", readOnly: true },
    { data: "paymentMode", name: "Payment Mode", type: "text", readOnly: true },
    {
      data: "billAmount",
      name: "Bill Amount",
      type: "numeric",
      readOnly: true,
    },
    {
      data: "paidAmount",
      name: "Paid Amount",
      type: "numeric",
      readOnly: true,
    },
    { data: "BPC", name: "BPC", type: "numeric", readOnly: true },
    { data: "BPCGST", name: "BPC GST", type: "numeric", readOnly: true },
    { data: "MDR", name: "MDR", type: "numeric", readOnly: true },
    { data: "MDRGST", name: "MDR GST", type: "numeric", readOnly: true },
    { data: "revenue", name: "Revenue", type: "numeric", readOnly: true },
    {
      data: "settledAmount",
      name: "Amount to settle",
      type: "numeric",
      readOnly: true,
    },
    {
      data: "fromAccount",
      name: "Settled from account",
      type: "text",
      readOnly: true,
    },
    {
      data: "fromIFSC",
      name: "Settled from IFSC",
      type: "text",
      readOnly: true,
    },
    {
      data: "toAccount",
      name: "Settled to account",
      type: "text",
      readOnly: true,
    },
    {
      data: "toIFSC",
      name: "Settled to IFSC",
      type: "text",
      readOnly: true,
    },
    {
      data: "paymentDate",
      name: "Payment Date",
      type: "date",
      readOnly: true,
      dateFormat: "YYYY-MM-DDTHH:mm:ssZ",
    },
  ];
  const colHeaders = columns.map((a) => a.name);

  const renderStats = () => {
    console.log(misUploadLoadable);
    if (isLoadableInProgress(misUploadLoadable)) {
      return <Spinner animation="border" />;
    }

    if (!hasLoadableLoaded(misUploadLoadable)) {
      return null;
    }

    const clearedRecords = misUploadLoadable.data.data.filter(
      (t) => !!t.clearingID
    );

    return (
      <Row>
        <Col>
          <Alert variant="info">
            Total records
            <br />
            {misUploadLoadable.data.metadata.totalMatchedRecords +
              misUploadLoadable.data.metadata.totalUnmatchedRecords}
          </Alert>
        </Col>
        <Col>
          <Alert variant="success">
            Valid records:
            <br />
            {misUploadLoadable.data.metadata.totalMatchedRecords}
          </Alert>
        </Col>
        <Col>
          <Alert variant="danger">
            Invalid records:
            <br />
            {misUploadLoadable.data.metadata.totalUnmatchedRecords}
          </Alert>
        </Col>
        <Col>
          <Alert variant="primary">
            Cleared records:
            <br />
            {clearedRecords.length} / {misUploadLoadable.data.data.length}
          </Alert>
        </Col>
      </Row>
    );
  };

  const onClickSettlementInitiate = () => {
    const initializeSettlementCall = () => {
      dispatch(
        initializeSettlement(
          misInputLoadable.data.paymentChannel,
          misInputLoadable.data.mis,
          true
        )
      )
        // @ts-ignore
        .then(() => {
          setSettlementInitiated(true);
          setUpdatedTransactions([]);
        });
    };
    const userConfirmed = window.confirm(
      updatedTransactions.length
        ? "Are you sure you want to save changes and initialize settlement?"
        : "Are you sure you want to initialize settlement?"
    );
    if (userConfirmed) {
      const toUpdateTransactions = updatedTransactions.length;

      if (toUpdateTransactions) {
        // @ts-ignore
        dispatch(updateTransactions(updatedTransactions)).then(() => {
          initializeSettlementCall();
        });
      } else {
        initializeSettlementCall();
      }
    }
  };

  const renderActions = () => {
    const text = updatedTransactions.length
      ? "Save and Initiate Settlement"
      : "Initiate Settlement";

    return (
      <>
        {settlementInitiated ? (
          <Button type="button" variant="primary" disabled>
            Settlement initiated
          </Button>
        ) : (
          <Button
            type="button"
            variant="primary"
            onClick={onClickSettlementInitiate}
            disabled={
              !hasLoadableLoaded(misUploadLoadable) ||
              !misUploadLoadable.data.data.length
              // misUploadLoadable.data.data.filter((t) => !!t.clearingID)
              //   .length !== misUploadLoadable.data.metadata.totalMatchedRecords
            }
          >
            {text}
          </Button>
        )}{" "}
        <Button
          variant="dark"
          type="button"
          disabled={
            !hasLoadableLoaded(misUploadLoadable) ||
            !misUploadLoadable.data.data.length
          }
          onClick={() => {
            if (!hasLoadableLoaded(misUploadLoadable)) {
              return;
            }

            const keyMappings: Dict<string> = columns.reduce((a, c) => {
              if (typeof c.data === "string") {
                a[c.data] = c.name;
              }
              return a;
            }, {});

            const csv = misUploadLoadable.data.data.map((t) => {
              const row: Object = {};

              Object.entries(keyMappings).forEach(([key, value]) => {
                row[value] = get(t, key, null);
              });

              return row;
            });
            fileDownload(Papa.unparse(csv), `clearing.csv`);
          }}
        >
          Download clearing file
        </Button>{" "}
        <Button
          variant="info"
          type="button"
          title={
            isRefreshBtnActive
              ? "Reupload MIS to refresh the table"
              : "Refresh button will be activated soon"
          }
          onClick={() => {
            dispatch(
              uploadMIS(
                misInputLoadable.data.paymentChannel,
                misInputLoadable.data.mis
              )
              // @ts-ignore
            ).finally(() => {
              setIsRefreshBtnActive(false);
            });
          }}
          disabled={
            !isRefreshBtnActive || isLoadableInProgress(misUploadLoadable)
          }
        >
          Refresh
        </Button>
      </>
    );
  };

  const renderTable = () => {
    if (!hasLoadableLoaded(misUploadLoadable)) {
      return null;
    }

    if (!misUploadLoadable.data.data.length) {
      return null;
    }

    return (
      <>
        <div>Note - All amounts are in paisa</div>
        <HotTable
          licenseKey={"non-commercial-and-evaluation"}
          settings={{
            data: [...misUploadLoadable.data.data],
            columns,
            colHeaders,
            rowHeaders: true,
            width: "100%",
            manualColumnResize: true,
            filters: true,
            dropdownMenu: [
              "filter_by_condition",
              "filter_operators",
              "filter_by_condition2",
              "filter_by_value",
              "filter_action_bar",
            ],
            contextMenu: false,
            columnSorting: true,
            afterChange: (rows, source) => {
              if (source === "edit" && rows && rows.length) {
                const updatedTs: Dict<UpdatedTransaction> = updatedTransactions.reduce(
                  (a: Dict<UpdatedTransaction>, b) => {
                    a[b.platformBillID] = b;
                    return a;
                  },
                  {}
                );
                rows.forEach((row) => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const [index, field, oldValue, newValue] = row;
                  const item = misUploadLoadable.data.data[index];
                  if (field === reconRequiredKey) {
                    updatedTs[item.platformBillID] = {
                      ...item,
                      [reconRequiredKey]: newValue,
                    };
                  }
                });
                setUpdatedTransactions(Object.values(updatedTs));
              }
            },
          }}
        />
      </>
    );
  };

  return (
    <>
      <div>{renderStats()}</div>
      <div className="mb-3">{renderActions()}</div>
      {renderTable()}
    </>
  );
};
